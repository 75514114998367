import  request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'
/**
 * get 请求 获取所有设备分类
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceClassAllService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/classification/list/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加设备分类
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addDeviceClassService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/classification/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 重新命名设备分类
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateDeviceClassService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/classification/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 升级设备分类
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const upgradeDeviceClassService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/classification/upgrade'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除设备分类
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delDeviceClassService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/classification/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 移动设备分类
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const deviceTypeMoveService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/move/classification'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


